var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aggrid" },
    _vm._l(_vm.list, function (item) {
      return _c(
        "span",
        { key: item.name },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                "popper-class": "file-info",
                effect: "light",
                placement: "top",
                "open-delay": 300,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "file-info",
                  attrs: { slot: "content" },
                  slot: "content",
                },
                [
                  _c(
                    "div",
                    { staticClass: "file-info-thumb" },
                    [
                      _vm.isImageFile(item.name)
                        ? _c("el-image", {
                            staticStyle: { width: "150px", height: "100px" },
                            attrs: {
                              src:
                                item.url +
                                "?x-oss-process=image/resize,m_fixed,h_100,w_150",
                            },
                          })
                        : _c("span", [_vm._v(_vm._s(item.name))]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "file-info-extra" }, [
                    _c("div", { staticClass: "file-info-size" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.countFileSize(item.size))),
                      ]),
                    ]),
                    _c("div", { staticClass: "file-info-actions" }, [
                      _c(
                        "a",
                        { attrs: { href: item.url, target: "_blank" } },
                        [_c("svg-icon", { attrs: { iconClass: "download2" } })],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm.isImageFile(item.name)
                ? _c("el-image", {
                    staticStyle: {
                      width: "25px",
                      height: "25px",
                      "margin-right": "3px",
                    },
                    attrs: {
                      src:
                        item.url +
                        "?x-oss-process=image/resize,m_fixed,h_30,w_30",
                      "preview-src-list": _vm.previewSrcList,
                    },
                  })
                : _c(
                    "el-image",
                    {
                      staticStyle: {
                        width: "26px",
                        height: "25px",
                        "margin-right": "3px",
                        cursor: "pointer",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error",
                        },
                        [
                          _c("svg-icon", {
                            staticStyle: {
                              width: "24px",
                              height: "25px",
                              "vertical-align": "middle",
                            },
                            attrs: { "icon-class": "fileType-xlsx" },
                            on: {
                              click: function ($event) {
                                return _vm.filePreview(item.url, true)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }