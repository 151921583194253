<template>
  <div class="aggrid">
  <p><el-image v-for="item in list" :key="item.sign" :src="item.sign + '?x-oss-process=image/resize,m_fixed,h_30,w_30'" :preview-src-list="previewSrcList" style="width: 25px; height: 25px; margin-right: 3px;"></el-image></p>
  </div>
</template>

<script>

export default {
  data () {
    return {
      list: [],
      previewSrcList: []
    }
  },
  beforeMount () {
    if(this.params.value){
      this.list = JSON.parse(this.params.value);
      this.previewSrcList = this.list.map(obj => obj.sign)
    }
  },
};
</script>
