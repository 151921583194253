<template>
  <div class="aggrid">

    <span v-for="user in list" :key="user.userId" style="margin-right: 8px;display: flex;align-items: center;">
      <el-tooltip popper-class="file-info" effect="light" placement="top" :open-delay="300">
      <div class="file-info" slot="content" style="width: 80px;">
        编号：{{ user.number }}
        <br/>
        <span>所属部门: <span v-if="!user.agencyUserDeptNames">--</span></span>
        <br/>
        <span v-for="(item, index) in user.agencyUserDeptNames" :key="index">
            <span>{{ index + 1 }}：{{ item }}</span> <br/>
        </span>
      </div>
      <div style="display: flex;align-items: center;cursor: pointer;">
        <el-avatar :size="25" :src="user.avatarUrl + '?x-oss-process=image/resize,m_fixed,h_30,w_30'" icon="el-icon-user-solid"></el-avatar><span style="margin-left: 3px; font-size: 13px;">{{ user.name }}</span>
      </div>
    </el-tooltip>

    </span>
  </div>
</template>

<script>

export default {
  data () {
    return {
      list: [],
    }
  },
  beforeMount () {
    if(this.params.value){
      this.list = this.params.value
    }
  },
};
</script>


