<template>
  <div class="aggrid">
    <el-rate v-if="value" v-model="value" disabled text-color="#ff9900"></el-rate>
  </div>
</template>

<script>

export default {
  data () {
    return {
      value: 0,
    }
  },
  beforeMount () {
    if(this.params.value){
      this.value = this.params.value
    }
  },
};
</script>


