<template>
  <div>
    <el-button type="text" status="primary" size="mini" content="查看" @click="onViewFillClick">查看</el-button>
    <el-divider direction="vertical"></el-divider>
    <el-button type="text" size="mini" status="primary" content="修改" @click="onEditFillClick">修改</el-button>
    <el-divider direction="vertical" v-if="true || this.currentRowData.status === 1 || this.currentRowData.status == 2"></el-divider>
    <el-button type="text" size="mini" status="warning" content="删除" @click="onDeleteManageFillClick" v-if="true || this.currentRowData.status === 1 || this.currentRowData.status == 2">删除</el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      currentRowData: '',
    }
  },
  beforeMount () {
    this.currentRowData = this.params.data
  },
  methods: {
    /**
     * 点击查看按钮
     * @param {string} fillId - 填充ID
     * @param {string} listType - 列表类型, FILL：我的填报, MANAGE：管理列表, AUDIT: 审核列表
     * @param {object} row - 行数据
     */
    onViewFillClick () {
      // 调用查看方法
      this.params.context.formAgGridParent.viewFill(this.currentRowData.fillId, 'MANAGE', this.currentRowData)
    },
    onEditFillClick () {
      // 调用编辑方法
      this.params.context.formAgGridParent.editFill(this.currentRowData.fillId, 'MANAGE', this.currentRowData)
    },
    onDeleteManageFillClick() {
      // 调用删除方法
      this.params.context.formAgGridParent.deleteFill(this.currentRowData.fillId, this.currentRowData.formId)
    }
  }
};
</script>

<style lang="scss" scoped>
.el-divider--vertical {
  margin: 0 5px;
}
</style>
