export default {
  template: `
      <div class="aggrid-custom-tooltip">
          <p>{{ value }}</p>
      </div>
    `,
  data: function () {
    return {
      value: '',
    };
  },
  beforeMount() {
    const { colDef, data } = this.params;
    let filedName = colDef.field.replace("fillJson.",'') + '__label';
    this.value = data.fillJson[filedName]
  },
};
