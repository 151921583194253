import { AG_GRID_LOCALE_ZH } from "./agLocaleText.js"; // 引入中文和英文

/**
 * 表格默认配置
 */
export const GRID_OPTIONS = {
  localeText: AG_GRID_LOCALE_ZH, // 中英文
  suppressContextMenu: false, // 关闭右键菜单列表
  suppressScrollOnNewData: true, // 网格在页面更改时不要滚动到顶部。
  // stopEditingWhenCellsLoseFocus: true, //在编辑的时候点击表格任何地方停止编辑
  headerHeight: 42, // 表头高度
  suppressMenuHide: true, // 默认显示menu图标
  tooltipMouseTrack: true, // 用鼠标跟踪以演示工具提示需要跟随光标的方案
  rowHeight: 38, // 设置行高为30px,默认情况下25px
  rowBuffer: 30, // 行缓冲区，默认为10行
  animateRows: true, // 开启行动画
  rowSelection: "multiple", // 行多选
  // cacheBlockSize: 100, // 缓存中的每个块应该包含多少行
  // rowMultiSelectWithClick: true,
  suppressRowClickSelection: false, // 点击即选择复选框
  tooltipShowDelay: 100, // 鼠标触摸提示出现时间100毫秒
  // groupSelectsChildren: true, // 选中子级
  // groupSelectsFiltered: true, // 勾选行组只获取子级数据
  enableRangeSelection: true,
  defaultColDef: {
    filter: true,
    // 默认的列配置
    menuTabs: ['filterMenuTab', 'generalMenuTab'], // 表头menuTabs，默认第一个为筛选器
    filterParams: {
      buttons: ["apply", "reset"], // 过滤器按钮
      closeOnApply: true, // 按住apply reset按钮关闭
      excelMode: "windows", // 转换为widows模式
      showTooltips: true // 设置过滤器工具提示
    },
    autoHeaderHeight: true, // 自适应表头高度
    sortable: true, // 可以排序
    headerCheckboxSelectionFilteredOnly: true, // 全选仅仅勾选筛选的全部
    resizable: true, // 允许调整列大小，就是拖动改变列大小
    // minWidth: 80, // 列最大宽度
    maxWidth: 300, // 列最大宽度
    flex: 1
  }
};

/**
 * 侧边栏配置
 */
export const SIDEBAR_CONFIGURATION = {
  toolPanels: [
    {
      id: "columns",
      labelDefault: "列筛选",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      minWidth: 225,
      width: 225,
      maxWidth: 225,
      toolPanelParams: {
        suppressRowGroups: false,
        suppressValues: false,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressColumnFilter: true,
        suppressColumnSelectAll: true,
        suppressColumnExpandAll: true
      }
    }
  ],
  position: "right", // 侧边栏在表格右侧显示
  defaultToolPanel: null // 默认收起侧边栏(指定为null找不到首先展示的)
};

/**
 * 侧边栏配置
 */
export const STATUSBAR_CONFIGURATION = {
  statusPanels: [
    // { statusPanel: "agTotalAndFilteredRowCountComponent", align: "left" },
    // { statusPanel: "agTotalRowCountComponent", align: "center" },
    { statusPanel: "agFilteredRowCountComponent" },
    { statusPanel: "agSelectedRowCountComponent" },
    { statusPanel: "agAggregationComponent" }
  ]
};

/**
 * 表格导出样式
 */
export const EXCELSTYLES = [
  {
    id: 'stringType',
    dataType: 'String',
  },
  {
    id: "oddBackcolor",
    interior: {
      color: "#ddebf7",
      pattern: "Solid"
    },
    // 边框
    borders: {
      color: "#ccc",
      lineStyle: "Continuous",
      weight: 1
    }
  },
  {
    // 必填 样式的ID，该id是唯一的字符串
    id: "header",
    // 字体设置
    font: {
      color: "block",
      size: 11,
      bold: true
    },
    alignment: {
      horizontal: "Left", // 水平
      vertical: "Center" // 垂直
    },
    // 边框
    borders: {
      borderBottom: {
        color: "#C0C0C0",
        lineStyle: "Continuous",
        weight: 1
      },
      borderLeft: {
        color: "#C0C0C0",
        lineStyle: "Continuous",
        weight: 1
      },
      borderRight: {
        color: "#C0C0C0",
        lineStyle: "Continuous",
        weight: 1
      },
      borderTop: {
        color: "#C0C0C0",
        lineStyle: "Continuous",
        weight: 1
      }
    },
    // 背景颜色和图案
    interior: {
      color: "#cdebf9",
      pattern: "Solid",
      patternColor: "#C0C0C0"
    }
  },
  {
    id: "cell",
    alignment: {
      horizontal: "Left", // 水平
      vertical: "Top", // 垂直
      wrapText: true // 文字超出换行
    }
  },
  {
    id: "headerGroup",
    alignment: {
      horizontal: "Center", // 水平
      vertical: "Center", // 垂直
      wrapText: true // 文字超出换行
    }
  },
  {
    id: "hyperlinks", // 链接样式
    font: {
      underline: "Single",
      color: "#358ccb"
    }
  }
];
