<template>
  <div class="ag-overlay-loading-center">
    <div class="ag-loading-center" aria-label="loading"></div>
    <div>{{params.loadingMessage}}</div>
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  beforeMount () {},
};
</script>
<style scoped>
.ag-loading-center {
  height:100px;
  width:100px;
  margin: 0 auto;
  background: url('./ag-grid-loading-spinner.svg') center / contain no-repeat;
}
</style>

