<template>
  <div class="aggrid">
    <p style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
        <el-tag v-for="item in list" :key="item.value" size="small" style="margin-right: 5px" type="info">{{ item.label }}</el-tag>
      </p>
  </div>
</template>

<script>

export default {
  data () {
    return {
      list: [],
    }
  },
  beforeMount () {
    const dicts = this.params.colDef.dict
    this.params.value.forEach(v => {
      const dict = dicts.find(item => item.value === v)
      if(dict){
        this.list.push(dict)
      }
    })
  },
};
</script>

