<template>
  <div class="aggrid">
    <span v-for="item in list" :key="item.name">
      <el-tooltip popper-class="file-info" effect="light" placement="top" :open-delay="300">
        <div class="file-info" slot="content">
          <div class="file-info-thumb">
            <el-image :src="item.url + '?x-oss-process=image/resize,m_fixed,h_100,w_150'"
              style="width: 150px; height: 100px;"></el-image>
          </div>
          <div class="file-info-extra">
            <div class="file-info-size">
              <span>{{ countFileSize(item.size) }}</span>
            </div>
            <div class="file-info-actions">
              <a :href="item.url" target="_blank">
                <svg-icon iconClass="download2"></svg-icon>
              </a>
            </div>
          </div>
        </div>
        <el-image :src="item.url + '?x-oss-process=image/resize,m_fixed,h_30,w_30'" :preview-src-list="previewSrcList"
          style="width: 25px; height: 25px; margin-right: 3px;"></el-image>
      </el-tooltip>
    </span>
  </div>
</template>

<script>
import { countFileSize } from "@/utils/agGrid.js";
export default {
  data () {
    return {
      list: [],
      previewSrcList: []
    }
  },
  beforeMount () {
    if (this.params.value) {
      this.list = this.params.value;
      this.previewSrcList = this.list.map(obj => obj.url);
    }
  },
  methods: {
    countFileSize (fileSize) {
      return countFileSize(fileSize);
    }
  }
};
</script>
