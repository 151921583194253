<template>
  <div class="aggrid">
    <span v-for="item in list" :key="item.name">
      <el-tooltip popper-class="file-info" effect="light" placement="top" :open-delay="300">
        <div class="file-info" slot="content">
          <div class="file-info-thumb">
            <el-image v-if="isImageFile(item.name)" :src="item.url + '?x-oss-process=image/resize,m_fixed,h_100,w_150'"
              style="width: 150px; height: 100px;"></el-image>
            <span v-else>{{ item.name }}</span>
          </div>
          <div class="file-info-extra">
            <div class="file-info-size">
              <span>{{ countFileSize(item.size) }}</span>
            </div>
            <div class="file-info-actions">
              <a :href="item.url" target="_blank">
                <svg-icon iconClass="download2"></svg-icon>
              </a>
            </div>
          </div>
        </div>
        <el-image v-if="isImageFile(item.name)" :src="item.url + '?x-oss-process=image/resize,m_fixed,h_30,w_30'"
          :preview-src-list="previewSrcList" style="width: 25px; height: 25px; margin-right: 3px;"></el-image>
        <el-image v-else style="width: 26px; height: 25px; margin-right: 3px;cursor: pointer;">
          <div slot="error" class="image-slot">
            <svg-icon :icon-class="'fileType-xlsx'" style="width: 24px; height: 25px; vertical-align:middle;" @click="filePreview(item.url, true)" />
          </div>
        </el-image>
      </el-tooltip>
    </span>
  </div>
</template>

<script>
import { countFileSize } from "@/utils/agGrid.js";
export default {
  data () {
    return {
      list: [],
      previewSrcList: []
    }
  },
  beforeMount () {
    if (this.params.value) {
      this.list = this.params.value;
      this.previewSrcList = this.list.map(obj => obj.url);
    }
  },
  methods: {
    fileTypeIcon (fileName) {
      return 'fileType-' + fileName.substring(fileName.lastIndexOf(".") + 1);
    },
    isImageFile (filename) {
      const imageExtensions = /\.(jpeg|jpg|gif|png|bmp)$/i;
      return imageExtensions.test(filename);
    },
    countFileSize (fileSize) {
      return countFileSize(fileSize);
    },
    filePreview (fileName, openWindow = false) {
      let base64FileName = btoa(fileName);
      let url = `${process.env.VUE_APP_BASE_FILE_PREVIEW
        }/onlinePreview?url=${encodeURIComponent(base64FileName)}`;
      if (openWindow) {
        window.open(url);
      }
      return url;
    }
  }
};
</script>
