var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("ag-grid-vue", {
        ref: "agGrid",
        class: "ag-theme-quartz",
        style: { height: _vm.rewriteHeight },
        attrs: {
          context: _vm.context,
          columnDefs: _vm.columnDefs,
          rowData: _vm.rowData,
          gridOptions: _vm.gridOptions,
          sideBar: _vm.rewriteSideBar,
          statusBar: _vm.rewriteStatusBar,
          excelStyles: _vm.tableExcelStyles,
          rowDragMultiRow: true,
          enableCharts: true,
          enableRangeSelection: true,
          enableRangeHandle: true,
          enableAdvancedFilter: _vm.enableAdvancedFilter,
          rowGroupPanelShow: _vm.rowGroupPanelShow,
          pagination: true,
          paginationPageSize: _vm.paginationPageSize,
          paginationPageSizeSelector: _vm.paginationPageSizeSelector,
          loadingOverlayComponent: _vm.loadingOverlayComponent,
          loadingOverlayComponentParams: _vm.loadingOverlayComponentParams,
          tooltipShowDelay: _vm.tooltipShowDelay,
          tooltipHideDelay: _vm.tooltipHideDelay,
          autoSizeStrategy: _vm.autoSizeStrategy,
          getContextMenuItems: _vm.getContextMenuItems,
          defaultExcelExportParams: _vm.defaultExcelExportParams,
        },
        on: {
          "grid-ready": _vm.onGridReady,
          "selection-changed": _vm.onSelectionChanged,
          filterChanged: _vm.onFilterChanged,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }