/**
 * 处理AgGrid列信息
 * @param {*} columnDefs 原始列数据
 * @param {*} firstColumn 是否添加序号列
 * @param {*} operationColumn 是否添加操作列
 * @returns
 */
export function getFinalColumnDefs(
  columnDefs,
  firstColumn = true,
  operationColumn = true
) {
  const columns = [];

  if (columnDefs && columnDefs.length > 0) {
    if (firstColumn) {
      const columns_number = [
        {
          field: "num",
          headerName: "#",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          pinned: true,
          valueGetter: hashValueGetter,
          width: 90,
          filter: false,
          menuTabs: [],
        },
      ];

      // 添加序号列
      columns.push.apply(columns, columns_number);
    }

    // 对业务字段表头进行处理
    columnDefs.forEach((item, index) => {
      // if (index == 0) {
      //   item.headerCheckboxSelection = true;
      //   item.checkboxSelection = true;
      //   item.checkboxSelectionCallback = function (params) {
      //     return params.valye.fillId;
      //   };
      // }

      if (item.hide === false) {
        // 文本类型
        if (item.fieldType === "input") {
          item.filter = "agTextColumnFilter";
          item.tooltipField = item.field;
        }
        // 多行文本
        else if (item.fieldType === "textarea") {
          item.filter = "agTextColumnFilter";
          item.tooltipField = item.field;
          item.maxWidth = 260;
        }

        // 单项选择
        else if (item.fieldType === "radio") {
          item.cellRenderer = "RadioCellRenderer";
          item.filter = "agSetColumnFilter";
          item.filterParams = {
            valueFormatter: selectFilterParams,
          };
        }

        // 多项选择
        else if (item.fieldType === "checkbox") {
          item.cellRenderer = "CheckboxCellRenderer";
          item.filter = "agSetColumnFilter";
          // item.keyCreator = (params) => params.colDef.dict.value
          item.filterParams = {
            valueFormatter: selectFilterParams,
          };
        }

        // 下拉选项
        else if (item.fieldType === "select") {
          item.cellRenderer = "SelectCellRenderer";
          item.filter = "agSetColumnFilter";
          item.filterParams = {
            valueFormatter: selectFilterParams,
          };
        }

        // 多级下拉
        else if (item.fieldType === "cascader") {
          item.cellRenderer = "CascaderCellRenderer";
          // item.tooltipField = item.field;
          item.filter = "agSetColumnFilter";
          item.filterParams = {
            valueFormatter: selectFilterParams,
          };
        }

        // 图片上传
        else if (item.fieldType === "picture-upload") {
          item.filter = false;
          item.cellRenderer = "PictureUploadCellRenderer";
        }

        // 文件上传
        else if (item.fieldType === "file-upload") {
          item.filter = false;
          item.cellRenderer = "FileUploadCellRenderer";
        }

        // 计数器
        else if (item.fieldType === "number") {
          item.filter = "agNumberColumnFilter";
          // item.cellRenderer = 'NumberCellRenderer'
        }

        // 评分
        else if (item.fieldType === "rate") {
          item.filter = "agNumberColumnFilter";
          item.cellRenderer = "RateCellRenderer";
        }

        // 日期
        else if (item.fieldType === "date") {
          item.minWidth = 120;
          item.cellDataType = "dateString";
          item.filter = "agDateColumnFilter";
          item.filterParams = dateFilterParams;
          // item.cellRenderer = 'DateCellRenderer'
        }

        // 省市区
        else if (item.fieldType === "region") {
          item.filter = "agSetColumnFilter";
          item.cellRenderer = "RegionCellRenderer";
          item.tooltipField = item.field;
        }

        // 地址
        else if (item.fieldType === "address") {
          item.filter = "asTextColumnFilter";
          item.cellRenderer = "AddressCellRenderer";
          // item.tooltipField = item.field;
        }

        // 民族
        else if (item.fieldType === "nationality") {
          item.cellRenderer = "NationalityCellRenderer";
          item.tooltipField = item.field;
          item.filter = "agSetColumnFilter";
        }

        // 数据关联
        else if (item.fieldType === "fill-select") {
          item.filter = false;
          item.cellRenderer = "FillSelectCellRenderer";
        }

        // 签字
        else if (item.fieldType === "sign") {
          item.filter = false;
          item.cellRenderer = "SignCellRenderer";
        }

        // 用户选择
        else if (item.fieldType === "user-select") {
          item.cellRenderer = "UserSelectCellRenderer";
          // item.filter = "agSetColumnFilter";
          // item.filterParams = {
          //   valueFormatter: userSelectFilterParams
          // }
          // item.tooltipComponent = 'UserSelectTooltip'
        }

        // 图片选择
        else if (item.fieldType === "picture-select") {
          item.filter = false;
          item.cellRenderer = "PictureSelectCellRenderer";
        }

        // 子表单
        else if (item.fieldType === "sub-form") {
          item.filter = false;
          item.cellRenderer = "SubFormCellRenderer";
        }

        // 矩阵表
        // else if (item.fieldType === 'matrix-form') {
        //   item.filter = false
        //   item.cellRenderer = 'MatrixFormCellRenderer'

        // }

        // 富文本
        else if (item.fieldType === "rich-editor") {
          // item.tooltipField = item.field
          item.cellRenderer = "RichEditorCellRenderer";
          item.tooltipValueGetter = (params) =>
            params.value.replace(/<[^>]+>/g, "");
        }

        // 引用字段
        else if (item.fieldType === "relative") {
          item.filter = false;
          item.cellRenderer = "RelativeCellRenderer";
        }

        if (item.field === "userId") {
          item.cellRenderer = "FillUserCellRenderer";
          // item.tooltipField = item.field;
          // item.tooltipComponent = "UserSelectTooltip";
        }
        if (item.field === "createTime") {
          item.minWidth = 120;
          item.cellDataType = "dateString";
          item.filter = "agDateColumnFilter";
          item.filterParams = dateFilterParams;
          item.cellRenderer = "DateCellRenderer";
        }
        if (item.field === "status") {
          item.cellRenderer = "StatusCellRenderer";
          item.filter = "agSetColumnFilter";
          item.filterParams = {
            valueFormatter: sysFieldFilterValueFormatter,
          };
        }
        if (item.field === "payStatus") {
          item.cellRenderer = "PayStatusCellRenderer";
          item.filter = "agSetColumnFilter";
          item.filterParams = {
            valueFormatter: sysFieldFilterValueFormatter,
          };
        }

        columns.push(item);
      }
    });

    // 是否添加操作列
    if (operationColumn) {
      // 操作列
      const columns_operation = [
        {
          field: "operation",
          headerName: "操作",
          maxWidth: 120,
          pinned: "right",
          cellRenderer: "OperationCellRenderer",
          filter: false,
          menuTabs: [],
          sortable: false,
        },
      ];
      columns.push.apply(columns, columns_operation);
    }
  }

  return columns;
}

export function hashValueGetter(params) {
  return params.node ? params.node.rowIndex + 1 : null;
}

export function businessFieldFilterValueFormatter(params) {
  const dicts = params.colDef.dict;
  const dict = dicts.find((item) => item.value == params.value);
  if (dict) {
    return dict.label;
  }
}

export function sysFieldFilterValueFormatter(params) {
  const dicts = params.colDef.dict;
  const dict = dicts.find((item) => item.value == params.value);
  if (dict) {
    return dict.label;
  }
}

export function selectFilterParams(params) {
  const dicts = params.colDef.dict;
  const dict = dicts.find((item) => item.value == params.value);
  if (dict) {
    return dict.label;
  }
}

export function userSelectFilterParams(params) {
  return params.value;
}

/**
 * 单选字段类型转换器
 * @param {*} params
 */
export function keyCreator(params) {
  var arr = params.colDef.dict;
  console.log(JSON.stringify(obj));
}
export function valueFormatter(params) {
  return params.value.name;
}

export function countFileSize(fileSize) {
  let i = 0;
  if (!fileSize) return "";
  let size = fileSize;
  const sizeName = ["B", "KB", "MB", "GB"];
  while (size > 1024) {
    size = size / 1024;
    i++;
  }
  return size.toFixed(2) + sizeName[i];
}

export function dateFilterParams() {
  const today = new Date();
  const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
  return {
    browserDatePicker: true,
    minValidDate: "2008-01-08",
    maxValidDate: tomorrow,
    buttons: ["apply", "reset"], // 过滤器按钮
    closeOnApply: true, // 按住apply reset按钮关闭
    excelMode: "windows", // 转换为widows模式
    showTooltips: true, // 设置过滤器工具提示
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("-");
      var cellDate = new Date(dateParts);
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
  };
}
