<template>
  <div class="aggrid">
    <p style="color: #3476f0; cursor: pointer;font-size: 13px;" @click="showRichTextDialog = true">{{ value.replace(/<[^>]+>/g, "") }}</p>
    <!-- 富文本查看对话框 -->
    <el-dialog :title="headerName" :visible.sync="showRichTextDialog" width="1000px" append-to-body>
      <!-- <rich-editor :value="value"></rich-editor> -->
      <div v-html="value"></div>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="showRichTextDialog=false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import RichEditor from "@/components/RichEditor";
export default {
  components: {
    // RichEditor
  },
  data () {
    return {
      showRichTextDialog: false,
      headerName: '',
      value: '',
    }
  },
  beforeMount () {
    this.headerName = '【' + this.params.colDef.headerName+ '】内容详情' || ''
    if(this.params.value){
      this.value = this.params.value
    }
  },
};
</script>
