<template>
  <div style="display:inline-block">
    <!-- <el-button size="mini" type="warning" :disabled="!selectAll && fillIds.length==0" style="height:30px;margin-left:10px;" @click="showDialog">
      批量删除
    </el-button> -->
    <!-- <span @click="showDialog">批量删除</span> -->
    <el-dialog title="确认删除" :visible.sync="showMultiDelete" custom-class="vFormViewerDialog" width="400px" append-to-body :before-close="close">
      <div class="container" style="margin-top:-20px">
        <div class="select-tip">请输入表单名称 <span style="color:#F18711;margin-left:5px;margin-right:5px;cursor:pointer" @click="copy">{{formName}}</span>以确认删除</div>
        <div>
          <el-input v-model="confirmFormName" placeholder="" clearable></el-input>
        </div>
        <div style="text-align:center;margin-top:15px;">
          <el-button type="default" size="small" @click="close">关闭</el-button>
          <el-button type="warning" size="small" @click="confirm">确认</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { fillBatchDel } from '@/api/system/form.js'
import { copyText } from '@/utils/index.js'
export default {
  props: {
    formId: {
      type: String
    },
    selectAll: {
      type: Boolean
    },
    formName: {
      type: String
    },
    formOptionData: {
      type: String | Object
    },
    fillIds: {
      type: Array
    },
    refresh: {
      type: Function
    },
    fillCacheKey: {
      type: String
    },
    excludeIds: {
      type: Array
    },
    showMultiDelete: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      confirmFormName: "",
      isShow: false
    }
  },
  methods: {
    showDialog () {
      this.isShow = true;
    },
    copy () {
      copyText(this.formName)
    },
    confirm () {
      if (this.confirmFormName === this.formName) {
        fillBatchDel(
          this.formId,
          (this.selectAll ? undefined : this.fillIds.map(x => x.fillId)),
          (this.selectAll ? 2 : 1),
          this.selectAll ? this.fillCacheKey : undefined,
          this.selectAll ? this.excludeIds : undefined
        ).then((data) => {
          this.$message({
            message: '删除成功',
            customClass: 'global-messageZIndex',
            type: 'success'
          })
          if (this.refresh) {
            this.refresh();
          }
          this.close()
        }).catch((err) => {
          this.msgError(err)
        })

      } else {
        if (this.confirmFormName == '') {
          this.$message({
            message: '请输入表单名称',
            customClass: 'global-messageZIndex',
            type: 'error'
          })
        } else {
          this.$message({
            message: '输入的表单名称不正确，请重新输入',
            customClass: 'global-messageZIndex',
            type: 'error'
          })
        }
      }
    },
    close () {
      this.$emit('update:showMultiDelete', false)
    }
  }
}
</script>
<style lang="scss" scoped>
.select-tip {
  height: 36px;
  line-height: 36px;
  margin-bottom: 5px;
  font-size: 16px;
  display: inline-block;
  width: 100%;
}
</style>
