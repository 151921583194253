<template>
  <div class="aggrid">
    <p>{{ value }}</p>
  </div>
</template>

<script>

export default {
  data () {
    return {
      value: '',
    }
  },
  beforeMount () {
    const add = this.params.value
    if(add){
      const arr = add.location
      let v;
      if(arr && arr.length > 0){
        v = arr.join(' / ')
        if(add.address){
          v = v + ' / ' + add.address
        }
      }
      this.value = v
    }
  },
};
</script>
