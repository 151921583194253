<template>
  <div class="aggrid">
    <el-tooltip popper-class="file-info" effect="light" placement="top" :open-delay="300">
      <div class="file-info" slot="content" style="width: 80px;">
        编号：{{ fillUserNumber }}
        <br/>
        <span>所属部门: <span v-if="!agencyDeptList">--</span></span>
        <br/>
        <span v-for="(item, index) in agencyDeptList" :key="index">
            <span>{{ index + 1 }}：{{ item.deptName }}</span> <br/>
        </span>
      </div>
      <div style="display: flex;align-items: center;cursor: pointer;">
        <el-avatar v-if="fillUserAvatarUrl" :size="25" :src="fillUserAvatarUrl + '?x-oss-process=image/resize,m_fixed,h_30,w_30'" icon="el-icon-user-solid"></el-avatar>
        <span style="margin-left: 3px;font-size: 13px;">{{ fillUserName }}</span>
      </div>
    </el-tooltip>
  </div>
</template>

<script>

export default {
  data () {
    return {
      fillUserNumber: '',
      fillUserAvatarUrl: '',
      fillUserName: '',
      agencyDeptList: []
    }
  },
  beforeMount () {
    this.fillUserNumber = this.params.data.fillUserNumber
    this.fillUserAvatarUrl = this.params.data.fillUserAvatarUrl
    this.fillUserName = this.params.data.fillUserName;
    this.agencyDeptList = this.params.data.agencyDeptList;
  },
};
</script>


