var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aggrid" },
    _vm._l(_vm.list, function (user) {
      return _c(
        "span",
        {
          key: user.userId,
          staticStyle: {
            "margin-right": "8px",
            display: "flex",
            "align-items": "center",
          },
        },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                "popper-class": "file-info",
                effect: "light",
                placement: "top",
                "open-delay": 300,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "file-info",
                  staticStyle: { width: "80px" },
                  attrs: { slot: "content" },
                  slot: "content",
                },
                [
                  _vm._v(" 编号：" + _vm._s(user.number) + " "),
                  _c("br"),
                  _c("span", [
                    _vm._v("所属部门: "),
                    !user.agencyUserDeptNames
                      ? _c("span", [_vm._v("--")])
                      : _vm._e(),
                  ]),
                  _c("br"),
                  _vm._l(user.agencyUserDeptNames, function (item, index) {
                    return _c("span", { key: index }, [
                      _c("span", [
                        _vm._v(_vm._s(index + 1) + "：" + _vm._s(item)),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                    ])
                  }),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "align-items": "center",
                    cursor: "pointer",
                  },
                },
                [
                  _c("el-avatar", {
                    attrs: {
                      size: 25,
                      src:
                        user.avatarUrl +
                        "?x-oss-process=image/resize,m_fixed,h_30,w_30",
                      icon: "el-icon-user-solid",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-left": "3px",
                        "font-size": "13px",
                      },
                    },
                    [_vm._v(_vm._s(user.name))]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }