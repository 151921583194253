<template>
  <div class="aggrid">
    <p style="color: #3476f0;cursor: pointer;" v-if="title" @click="onFillSelectViewClick"><i class="el-icon-link"></i> <span style="font-size: 13px;">{{ title }}</span></p>
  </div>
</template>

<script>

export default {
  data () {
    return {
      title: '',
      currentRowData: null
    }
  },
  beforeMount () {
    this.currentValue = this.params.value
    this.currentRowData = this.params.data
    if (this.params.value) {
      const arr = this.params.value.selectList
      if (arr && arr.length > 0) {
        let titleName = this.params.value.titleName
        this.title = arr[0][titleName]
      }
    }
  },
  methods: {
    onFillSelectViewClick () {
      // 调用查看方法
      this.params.context.formAgGridParent.fillSelectView(this.currentValue.formId, this.currentValue.selectList[0].fillId, null)
    },
  }
};
</script>


