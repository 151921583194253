var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "aggrid" }, [
    _c(
      "p",
      _vm._l(_vm.list, function (item) {
        return _c("el-image", {
          key: item.sign,
          staticStyle: { width: "25px", height: "25px", "margin-right": "3px" },
          attrs: {
            src: item.sign + "?x-oss-process=image/resize,m_fixed,h_30,w_30",
            "preview-src-list": _vm.previewSrcList,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }