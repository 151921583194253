<template>
  <div class="aggrid">
    <el-tag v-if="value" size="small" type="info">{{ value }}</el-tag>
  </div>
</template>

<script>

export default {
  data () {
    return {
      value: '',
    }
  },
  beforeMount () {
    if(this.params.value){
      const dicts = this.params.colDef.dict
      const dict = dicts.find(item => item.value == this.params.value)
      this.value = dict.label
    }
  },
};
</script>

