<template>
  <div>
    <el-row type="flex" justify="space-between" :gutter="20">
      <el-col :span="12">
        <el-tag v-for="item in filterModel" :key="item.field" size="small" style="margin-right: 5px;" closable @close="closeFilter(item.field)">
          {{ item.fieldName }}:
          <span v-if="item.filterType === 'set'">{{ item.values.join(',') }}</span>
          <span v-else>{{ item.filter }}</span>
        </el-tag>
      </el-col>
      <el-col :span="12" style="text-align: right;">
        <el-form :inline="true" size="small" @submit.native.prevent="() => { }">
          <el-form-item style="margin: 0 0 10px 15px;">
            <el-input v-model="searchText" id="filter-text" placeholder="输入内容搜索" prefix-icon="el-icon-search"
              clearable></el-input>
          </el-form-item>
          <el-form-item style="margin: 0 0 10px 15px;">
            <el-tooltip class="item" effect="dark" content="分享" placement="top">
              <el-link icon="el-icon-share" :underline="false" @click="onHandleShareClick"></el-link>
            </el-tooltip>
          </el-form-item>
          <el-form-item style="margin: 0 0 10px 15px;">
            <el-dropdown>
              <el-button style="background: #f2f5f7; " :style="selectedRows.length > 0
                ? 'border: 1px solid #3476f0; '
                : 'border: 1px solid #dcdfe6;'
                ">
                批量操作 {{ selectedRows.length > 0 ? selectedRows.length + " 条" : '' }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="onExportForGridClick('excel')"
                  data-tag="excel">批量导出为Excel</el-dropdown-item>
                <el-dropdown-item @click.native="onExportForGridClick('picexcel')"
                  data-tag="picexcel">导出为带图片的Excel</el-dropdown-item>
                <!-- <el-dropdown-item @click.native="onExportForGridClick('excel2')"
                  data-tag="excel2">Excel的合并导出</el-dropdown-item> -->
                <el-dropdown-item @click.native="onExportForGridClick('file')" data-tag="file">导出附件</el-dropdown-item>
                <el-dropdown-item @click.native="onExportForGridClick('word')"
                  data-tag="word">导出word/pdf文档</el-dropdown-item>
                <el-divider></el-divider>
                <el-dropdown-item @click.native="onBatchImportClick">批量导入</el-dropdown-item>
                <el-dropdown-item @click.native="showMultiEdit = true" :disabled="selectedRows.length === 0">
                  批量编辑
                </el-dropdown-item>
                <el-dropdown-item @click.native="showMultiDelete = true" :disabled="selectedRows.length === 0">
                  批量删除
                </el-dropdown-item>
                <el-divider></el-divider>
                <el-dropdown-item @click.native="onHandleNoticeClick">发布通知</el-dropdown-item>
                <el-divider v-if="formDetail.pay"></el-divider>
                <!-- <el-dropdown-item @click.native="onBatchPayClick" :disabled="selectedRows.length === 0">支付</el-dropdown-item> -->
                <el-dropdown-item v-if="formDetail.pay" @click.native="editPayPriceOpen = true"
                  :disabled="selectedRows.length == 0">修改订单金额</el-dropdown-item>
                <el-dropdown-item v-if="formDetail.pay" @click.native="confirmPayStatusOpen = true"
                  :disabled="selectedRows.length == 0">调整支付状态</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
          <el-form-item style="margin: 0 0 10px 15px;">
            <el-button type="primary" @click="onFillClickCheck" :disabled="disableFill">开始填报</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <ag-grid-table ref="formAgGrid"
      :context="context"
      :columnDefs="columnDefs"
      :rowData="rowData"
      :searchText="searchText"
      :height="height"
      :showSideBar="showSideBar"
      :autoSizeStrategy="autoSizeStrategy"
      @selectedRowsEvent="getSelectedRows"
      @filterChangedEvent="getFilterModel"></ag-grid-table>

    <!-- 批量编辑 -->
    <multi-edit :showMultiEdit.sync="showMultiEdit" :formJson="formJson" :formId="formId" :refresh="getRowData"
      :formOptionData="formOptionData" :fillIds="selectedRows" :fillCacheKey="fillCacheKey"
      :excludeIds="excludeIds"></multi-edit>
    <!-- 批量删除 -->
    <multi-delete :showMultiDelete.sync="showMultiDelete" :formJson="formJson" :formId="formId" :refresh="getRowData"
      :formOptionData="formOptionData" :fillIds="selectedRows" :fillCacheKey="fillCacheKey"
      :excludeIds="excludeIds"></multi-delete>

    <!--数据查看列表，修改订单金额-->
    <el-dialog title="修改订单金额" :visible.sync="editPayPriceOpen" width="400px" append-to-body
      @close="onCloseEditPayPriceClick">
      <el-form ref="editPayPriceForm" label-width="80px" class="clearfix" label-position="left" @submit.native.prevent>
        <el-form-item label="订单金额" prop="pay">
          <el-input type="text" placeholder="请输入支付金额" v-model="editPayPrice"
            style="width:200px; display: inline-block;"></el-input>
          <span style="margin-left: 10px">元</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="onConfirmEditPayPriceClick">确 定</el-button>
        <el-button @click="onCloseEditPayPriceClick">取 消</el-button>
      </div>
    </el-dialog>

    <!--数据查看列表，确认到款-->
    <el-dialog title="确认支付" :visible.sync="confirmPayStatusOpen" width="400px" append-to-body
      @close="onCloseEditPayStatusClick">
      <el-form ref="confirmPriceForm" label-width="80px" class="clearfix" label-position="left" @submit.native.prevent>
        <el-form-item label="支付状态" prop="payStatus">
          <el-select v-model="confirmPayStatus" placeholder="请选择支付状态">
            <el-option label="未支付" value="2"></el-option>
            <el-option label="确认到款" value="4"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="onConfirmPayStatusClick">确 定</el-button>
        <el-button @click="onCloseEditPayStatusClick">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import AgGridTable from "@/components/AgGridTable";
import MultiEdit from "@/views/system/form/components/multiEdit/index.vue";
import MultiDelete from "@/views/system/form/components/multiDelete/index.vue";
import { getAgGridColumns, getAgGridRowData } from "@/api/system/agGrid";
import { getFinalColumnDefs } from "@/utils/agGrid.js";
import { orderPriceEdit, payStatusEdit } from "@/api/system/form";
export default {
  name: "AgGrid",
  components: {
    AgGridTable,
    MultiEdit,
    MultiDelete
  },
  props: {
    formId: {
      type: String,
      default: ''
    },
    height: {
      type: [Number, String],
      default: 145
    },
    formDetail: {
      type: Object,
      default: null
    },
    showMultiEdit: {
      type: Boolean,
      default: false
    },
    showMultiDelete: {
      type: Boolean,
      default: false
    },
    // 表格侧边栏显示隐藏
    showSideBar: {
      type: Boolean,
      default: true
    },
    formOptionData: {
      type: String | Object
    },
    formJson: {
      type: String | Object
    },
    fillCacheKey: {
      type: String
    },
    excludeIds: {
      type: Array
    },
    fillInsert: {
      type: Function,
      default: null
    },
    /**
     * 查看详情
     */
    viewFill: {
      type: Function,
      default: null
    },
    /**
     * 编辑填报数据
     */
    editFill: {
      type: Function,
      default: null
    },
    /**
     * 删除填报
     */
    deleteFill: {
      type: Function,
      default: null
    },
    /**
     * 查看数据关联字段详情
     */
    fillSelectView: {
      type: Function,
      default: null
    },
    /**
     * 分享
     */
    share: {
      type: Function,
      default: null
    },
    /**
     * 导出
     */
    export: {
      type: Function,
      default: null
    },
    /**
     * 批量导入
     */
    batchImport: {
      type: Function,
      default: null
    },
    /**
     * 批量支付
     */
    batchPay: {
      type: Function,
      default: null
    },
    batchEditPayPrice: {
      type: Function,
      default: null
    },
    batchConfirmPay: {
      type: Function,
      default: null
    },
    viewId:{
      type: String,
      default: ''
    },
    /**
     * 发布通知
     */
    notice: {
      type: Function,
      default: null
    }

  },
  data () {
    return {
      context: {},
      columnDefs: [],
      rowData: [],
      searchText: '',
      disableFill: false,
      selectedRows: [],
      filterModel: [],
      editPayPriceOpen: false,
      editPayPrice: 0,
      confirmPayStatusOpen: false,
      confirmPayStatus: null,
      autoSizeStrategy: null
    };
  },
  beforeMount () {
    this.context = {
      formAgGridParent: this
    }
  },
  created () {
    this.getAgGridColumns()
  },
  methods: {

    getAgGridColumns () {
      getAgGridColumns(this.formId).then(res => {
        if (res.code === 200) {
          // 处理表头配置
          this.columnDefs = getFinalColumnDefs(res.data);
          if(this.columnDefs.length > 12){
            this.autoSizeStrategy = {
              type: 'fitCellContents'
            }
          }
          // 开始加载数据
          this.getRowData(this.formId)
        }
      })
    },
    getRowData () {
      this.$refs.formAgGrid.onBtShowLoading()
      getAgGridRowData(this.formId).then(res => {
        this.rowData = res.data
        this.$refs.formAgGrid.onBtHide()
      }).catch(err => {
        this.$refs.formAgGrid.onBtHide()
      })
    },
    getSelectedRows (data) {
      this.selectedRows = data
    },
    getFilterModel (data) {
      const _this = this
      _this.filterModel = []
      Object.entries(data).forEach(([key, value]) => {
        const arr = _this.columnDefs.find((item) => item.field === key)
        console.log(arr)
        let filterM = {
          field: key,
          fieldName: arr.headerName,
          filter: value.filter,
          filterType: value.filterType,
          type: value.type,
          values: value.values
        }
        _this.filterModel.push(filterM)
      });
    },
    onExportForGridClick (type) {
      if (this.selectedRows && this.selectedRows.length > 0) {
        this.export(type, this.selectedRows.map(x => x.fillId))
      }
    },
    onHandleShareClick () {
      this.share()
    },
    onFillClickCheck () {
      this.fillInsert()
    },
    onBatchImportClick () {
      this.batchImport()
    },
    onBatchPayClick () {
      this.batchPay(this.selectedRows)
    },
    onHandleNoticeClick () {
      this.notice(this.formDetail)
    },
    onConfirmPayClick () {
      this.batchConfirmPay(this.selectedRows)
    },
    onCloseEditPayPriceClick () {
      this.editPayPriceOpen = false
    },
    // 表单开通支付，管理员确定修改支付金额
    onConfirmEditPayPriceClick () {
      const inconformityArr = this.selectedRows.find(x => x.payStatus === 3 || x.payStatus === 4);
      if (inconformityArr && inconformityArr.length > 0) {
        this.$message({
          type: "warning",
          message: "选中的数据中，存在“已支付”或“确认支付”状态，修改失败～",
        });
        return;
      }
      if (this.isBlank(this.editPayPrice)) {
        this.$message({
          type: "warning",
          message: "支付金额不能为空～",
        });
        return;
      }
      const n = Number(this.editPayPrice);
      if (isNaN(n)) {
        this.$message({
          type: "warning",
          message: "支付金额格式有误～",
        });
        return;
      } else if (n < 0.01) {
        this.$message({
          type: "warning",
          message: "设置支付金额最小为0.01元～",
        });
        return;
      } else {
        this.isRegPrice(n);
      }
      const fillIds = this.selectedRows.map(x => x.fillId);
      const dto = {
        fillIds: fillIds,
        formId: this.formId,
        orderPrice: this.editPayPrice * 100,
      };
      orderPriceEdit(dto).then((response) => {
        if (response.code == 200) {
          this.msgSuccess("修改成功");
          this.editPayPrice = 0;
          this.editPayPriceOpen = false;
          this.getRowData();
        }
      });
    },
    isRegPrice (total) {
      const dot = String(total).indexOf(".");
      if (dot != -1) {
        const dotCnt = String(total).substring(dot + 1, total.length);
        if (dotCnt.length > 2) {
          total = total.toFixed(2);
          this.$message({
            type: "warning",
            message: "支付金额只保留两位小数哦～",
          });
          this.editPayPrice = total;
        }
      }
    },
    onConfirmPayStatusClick () {

      const inconformityArr = [];
      // console.log('支付状态', _this.confirmPayStatus)
      if (this.confirmPayStatus == 2) {
        const inconformityArr = this.selectedRows.find(x => x.payStatus === 2 || x.payStatus === 3);
        if (inconformityArr && inconformityArr.length > 0) {
          this.$message({
            type: "warning",
            message: "选中的数据中，存在“已支付”或“未支付”，修改失败～",
          });
          return;
        }
      }
      if (this.confirmPayStatus == 4) {
        // 选择的确认到款的状态
        const inconformityArr = this.selectedRows.find(x => x.payStatus === 3 || x.payStatus === 4);
        if (inconformityArr && inconformityArr.length > 0) {
          this.$message({
            type: "warning",
            message: "选中的数据中，存在“已支付”或“确认支付”状态，修改失败～",
          });
          return;
        }
      }
      const fillIds = this.selectedRows.map(x => x.fillId);
      const dto = {
        fillIds: fillIds,
        formId: this.formId,
        payStatus: this.confirmPayStatus,
      };
      payStatusEdit(dto).then((response) => {
        if (response.code == 200) {
          this.msgSuccess("修改成功");
          this.confirmPayStatus = null;
          this.confirmPayStatusOpen = false;
          this.getRowData();
        }
      });
    },
    // 表单开通支付，管理员关闭修改支付状态
    onCloseEditPayStatusClick () {
      this.confirmPayStatus = null;
      this.confirmPayStatusOpen = false;
    },
    closeFilter (field) {
      this.$refs.formAgGrid.closeFilterModel(field, null)
    }
  }
}
</script>
