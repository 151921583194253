import request from "@/utils/request";

// 获取列表表头
export function getAgGridColumns(formId) {
  return request({
    url: "/agGrid/columnDefs/" + formId,
    method: "get",
  });
}

// 获取列表数据
export function getAgGridRowData(formId) {
  return request({
    url: "/agGrid/rowData/" + formId,
    method: "get",
  });
}
