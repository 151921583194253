var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aggrid" },
    [
      _vm.value
        ? _c("el-tag", { attrs: { type: "info", size: "small" } }, [
            _vm._v(_vm._s(_vm.value.label)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }