export const AG_GRID_LOCALE_ZH = {
  // Set Filter
  selectAll: "(全选)",
  selectAllSearchResults: "(选择所有搜索结果)",
  addCurrentSelectionToFilter: "将当前选择添加到筛选",
  searchOoo: "搜索...",
  blanks: "(空)",
  noMatches: "没有匹配项",

  // Number Filter & Text Filter
  filterOoo: "过滤...",
  equals: "等于",
  notEqual: "不等于",
  blank: "空",
  notBlank: "不为空",
  empty: "选择一个",

  // Number Filter
  lessThan: "小于",
  greaterThan: "大于",
  lessThanOrEqual: "小于等于",
  greaterThanOrEqual: "大于等于",
  inRange: "在范围内",
  inRangeStart: "from",
  inRangeEnd: "to",

  // Text Filter
  contains: "包含",
  notContains: "不包含",
  startsWith: "开始于",
  endsWith: "结束于",

  // Date Filter
  dateFormatOoo: "yyyy-mm-dd",
  before: '之前',
  after: '之后',

  // Filter Conditions
  andCondition: "并且",
  orCondition: "或者",

  // Filter Buttons
  applyFilter: "搜索",
  resetFilter: "重置",
  clearFilter: "清除",
  cancelFilter: "取消",

  // Filter Titles
  textFilter: "文本过滤器",
  numberFilter: "数字过滤器",
  dateFilter: "日期过滤器",
  setFilter: "Set Filter",

  // Group Column Filter
  groupFilterSelect: "选择 字段:",

  // Advanced Filter
  advancedFilterContains: "包含",
  advancedFilterNotContains: "不包含",
  advancedFilterTextEquals: "等于",
  advancedFilterTextNotEqual: "不等于",
  advancedFilterStartsWith: "开始于",
  advancedFilterEndsWith: "结束于",
  advancedFilterBlank: "为空",
  advancedFilterNotBlank: "不为空",
  advancedFilterEquals: "=",
  advancedFilterNotEqual: "!=",
  advancedFilterGreaterThan: ">",
  advancedFilterGreaterThanOrEqual: ">=",
  advancedFilterLessThan: "<",
  advancedFilterLessThanOrEqual: "<=",
  advancedFilterTrue: "is true",
  advancedFilterFalse: "is false",
  advancedFilterAnd: "并且",
  advancedFilterOr: "或",
  advancedFilterApply: "搜索",
  advancedFilterBuilder: "筛选器",
  advancedFilterValidationMissingColumn: "缺少列",
  advancedFilterValidationMissingOption: "缺少选项",
  advancedFilterValidationMissingValue: "缺少值",
  advancedFilterValidationInvalidColumn: "未找到列",
  advancedFilterValidationInvalidOption: "未找到选项",
  advancedFilterValidationMissingQuote: "值缺少结束引号",
  advancedFilterValidationNotANumber: "请输入数字",
  advancedFilterValidationInvalidDate: "日期格式不正确",
  advancedFilterValidationMissingCondition: "缺少条件",
  advancedFilterValidationJoinOperatorMismatch:
    "Join operators within a condition must be the same",
  advancedFilterValidationInvalidJoinOperator: "Join operator not found",
  advancedFilterValidationMissingEndBracket: "Missing end bracket",
  advancedFilterValidationExtraEndBracket: "Too many end brackets",
  advancedFilterValidationMessage: "表达式有误. ${variable1} - ${variable2}.",
  advancedFilterValidationMessageAtEnd:
    "表达式有误. ${variable} 应在表达式末尾.",
  advancedFilterBuilderTitle: "高级过滤",
  advancedFilterBuilderApply: "应用",
  advancedFilterBuilderCancel: "取消",
  advancedFilterBuilderAddButtonTooltip: "添加筛选或组",
  advancedFilterBuilderRemoveButtonTooltip: "移除",
  advancedFilterBuilderMoveUpButtonTooltip: "上移",
  advancedFilterBuilderMoveDownButtonTooltip: "下移",
  advancedFilterBuilderAddJoin: "添加组",
  advancedFilterBuilderAddCondition: "添加过滤",
  advancedFilterBuilderSelectColumn: "选择一列",
  advancedFilterBuilderSelectOption: "选择一个选项",
  advancedFilterBuilderEnterValue: "输入值...",
  advancedFilterBuilderValidationAlreadyApplied: "当前过滤已存在.",
  advancedFilterBuilderValidationIncomplete: "条件不完整.",
  advancedFilterBuilderValidationSelectColumn: "必须选择一列.",
  advancedFilterBuilderValidationSelectOption: "必须选择一个选项.",
  advancedFilterBuilderValidationEnterValue: "必须输入内容.",

  // Side Bar
  columns: "列筛选",
  filters: "过滤",

  // columns tool panel
  pivotMode: "数据透视模式",
  groups: "分组字段",
  rowGroupColumnsEmptyMessage: "将字段拖到此处可设置分组",
  values: "聚合函数",
  valueColumnsEmptyMessage: "拖字段到此处进行聚合计算",
  pivots: "列标签",
  pivotColumnsEmptyMessage: "拖到此处可设置列标签",

  // Header of the Default Group Column
  group: "分组",

  // Row Drag
  rowDragRow: "row",
  rowDragRows: "rows",

  // Other
  loadingOoo: "加载中...",
  loadingError: "加载失败",
  noRowsToShow: "没有数据可显示",
  enabled: "已启用",

  // Menu
  pinColumn: "固定列",
  pinLeft: "固定在左边",
  pinRight: "固定在右边",
  noPin: "不固定",
  valueAggregation: "聚合数值",
  noAggregation: "None",
  autosizeThiscolumn: "自动调整此列",
  autosizeAllColumns: "自动调整所有列",
  groupBy: "分组",
  ungroupBy: "取消分组",
  ungroupAll: "取消全部分组",
  addToValues: "添加 ${variable} 到值",
  removeFromValues: "从值中移除 ${variable}",
  addToLabels: "添加 ${variable} 到标签",
  removeFromLabels: "从标签中移除 ${variable}",
  resetColumns: "重置列",
  expandAll: "展开所有行组",
  collapseAll: "关闭所有行组",
  copy: "复制",
  ctrlC: "Ctrl+C",
  ctrlX: "Ctrl+X",
  copyWithHeaders: "同时复制表头",
  copyWithGroupHeaders: "使用组表头复制",
  cut: "剪切",
  paste: "粘贴",
  ctrlV: "Ctrl+V",
  export: "导出",
  csvExport: "导出为CSV",
  excelExport: "导出为Excel",

  // Enterprise Menu Aggregation and Status Bar
  sum: "求和",
  first: "第一",
  last: "最后",
  min: "最小值",
  max: "最大值",
  none: "None",
  count: "数量",
  avg: "平均值",
  filteredRows: "已筛选记录",
  selectedRows: "已选择记录",
  totalRows: "总条数",
  totalAndFilteredRows: "条数",
  more: "更多",
  to: "-",
  of: "总计",
  page: "Page",
  pageLastRowUnknown: "?",
  nextPage: "下一页",
  lastPage: "最后一页",
  firstPage: "首页",
  previousPage: "上一页",
  pageSizeSelectorLabel: '每页显示条数:',

  // Pivoting
  pivotColumnGroupTotals: "总数",

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: "透视图 & 透视模式",
  pivotChart: "数据透视图",
  chartRange: "图表范围",

  columnChart: "柱状图",
  groupedColumn: "分组",
  stackedColumn: "堆叠",
  normalizedColumn: "100% 堆叠",

  barChart: "条形图",
  groupedBar: "分组",
  stackedBar: "堆叠",
  normalizedBar: "100% 堆叠",

  pieChart: "饼图",
  pie: "Pie",
  doughnut: "甜甜圈",

  line: "折线图",

  xyChart: "X Y (散点图)",
  scatter: "散点",
  bubble: "Bubble",

  areaChart: "面积图",
  area: "Area",
  stackedArea: "堆叠",
  normalizedArea: "100% 堆叠",

  histogramChart: "直方图",
  histogramFrequency: "频率",

  combinationChart: "组合图",
  columnLineCombo: "柱状图 & 折线图",
  AreaColumnCombo: "面积图 & 柱状图",

  // Charts
  pivotChartTitle: "数据透视图",
  rangeChartTitle: "范围图",
  settings: "设置",
  data: "数据",
  format: "格式化",
  categories: "类别",
  defaultCategory: "(无)",
  series: "系列",
  xyValues: "X Y Values",
  paired: "配对模式",
  axis: "轴",
  navigator: "导航",
  color: "颜色",
  thickness: "Thickness",
  xType: "X Type",
  automatic: "自动",
  category: "类别",
  number: "数字",
  time: "时间",
  autoRotate: "自动旋转",
  xRotation: "X轴旋转",
  yRotation: "Y轴旋转",
  ticks: "Ticks",
  width: "宽度",
  height: "高度",
  length: "长度",
  padding: "内边距",
  spacing: "间距",
  chart: "图表",
  title: "标题",
  titlePlaceholder: "图表标题 - 双击修改",
  background: "背景",
  font: "字体",
  top: "顶部",
  right: "右边",
  bottom: "底部",
  left: "左边",
  labels: "标签",
  size: "大小",
  minSize: "最小尺寸",
  maxSize: "最大尺寸",
  legend: "图例",
  position: "位置",
  markerSize: "标记大小",
  markerStroke: "标记笔划",
  markerPadding: "标记填充",
  itemSpacing: "Item Spacing",
  itemPaddingX: "Item Padding X",
  itemPaddingY: "Item Padding Y",
  layoutHorizontalSpacing: "水平间距",
  layoutVerticalSpacing: "垂直间距",
  strokeWidth: "Stroke Width",
  lineDash: "划线",
  offset: "Offset",
  offsets: "Offsets",
  tooltips: "Tooltips",
  callout: "Callout",
  markers: "Markers",
  shadow: "Shadow",
  blur: "Blur",
  xOffset: "X Offset",
  yOffset: "Y Offset",
  lineWidth: "Line Width",
  normal: "默认",
  bold: "粗体",
  italic: "斜体",
  boldItalic: "粗体斜体",
  predefined: "Predefined",
  fillOpacity: "Fill Opacity",
  strokeOpacity: "Line Opacity",
  histogramBinCount: "Bin count",
  columnGroup: "Column",
  barGroup: "Bar",
  pieGroup: "Pie",
  lineGroup: "Line",
  scatterGroup: "X Y (Scatter)",
  areaGroup: "Area",
  histogramGroup: "Histogram",
  combinationGroup: "Combination",
  groupedColumnTooltip: "Grouped",
  stackedColumnTooltip: "Stacked",
  normalizedColumnTooltip: "100% Stacked",
  groupedBarTooltip: "Grouped",
  stackedBarTooltip: "Stacked",
  normalizedBarTooltip: "100% Stacked",
  pieTooltip: "Pie",
  doughnutTooltip: "Doughnut",
  lineTooltip: "Line",
  groupedAreaTooltip: "Area",
  stackedAreaTooltip: "Stacked",
  normalizedAreaTooltip: "100% Stacked",
  scatterTooltip: "Scatter",
  bubbleTooltip: "Bubble",
  histogramTooltip: "Histogram",
  columnLineComboTooltip: "Column & Line",
  areaColumnComboTooltip: "Area & Column",
  customComboTooltip: "Custom Combination",
  noDataToChart: "No data available to be charted.",
  pivotChartRequiresPivotMode: "Pivot Chart requires Pivot Mode enabled.",
  chartSettingsToolbarTooltip: "Menu",
  chartLinkToolbarTooltip: "Linked to Grid",
  chartUnlinkToolbarTooltip: "Unlinked from Grid",
  chartDownloadToolbarTooltip: "Download Chart",
  seriesChartType: "Series Chart Type",
  seriesType: "Series Type",
  secondaryAxis: "Secondary Axis",

  // ARIA
  ariaAdvancedFilterBuilderItem:
    "${variable1}. Level ${variable2}. Press ENTER to edit.",
  ariaAdvancedFilterBuilderItemValidation:
    "${variable1}. Level ${variable2}. ${variable3} Press ENTER to edit.",
  ariaAdvancedFilterBuilderList: "Advanced Filter Builder List",
  ariaAdvancedFilterBuilderFilterItem: "Filter Condition",
  ariaAdvancedFilterBuilderGroupItem: "Filter Group",
  ariaAdvancedFilterBuilderColumn: "Column",
  ariaAdvancedFilterBuilderOption: "Option",
  ariaAdvancedFilterBuilderValueP: "Value",
  ariaAdvancedFilterBuilderJoinOperator: "Join Operator",
  ariaAdvancedFilterInput: "Advanced Filter Input",
  ariaChecked: "checked",
  ariaColumn: "Column",
  ariaColumnGroup: "Column Group",
  ariaColumnList: "Column List",
  ariaColumnSelectAll: "Toggle Select All Columns",
  ariaDateFilterInput: "Date Filter Input",
  ariaDefaultListName: "List",
  ariaFilterColumnsInput: "Filter Columns Input",
  ariaFilterFromValue: "Filter from value",
  ariaFilterInput: "Filter Input",
  ariaFilterList: "Filter List",
  ariaFilterToValue: "Filter to value",
  ariaFilterValue: "Filter Value",
  ariaFilterMenuOpen: "打开过滤菜单",
  ariaFilteringOperator: "过滤操作",
  ariaHidden: "隐藏",
  ariaIndeterminate: "不确定的",
  ariaInputEditor: "输入编辑器",
  ariaMenuColumn: "按 CTRL ENTER 打开列菜单.",
  ariaRowDeselect: "按 SPACE 取消选择此行",
  ariaRowSelectAll: "按 Space 选择所有行",
  ariaRowToggleSelection: "按 Space 选择此行",
  ariaRowSelect: "按 SPACE 选择此行",
  ariaSearch: "搜索",
  ariaSortableColumn: "按 ENTER 排序",
  ariaToggleVisibility: "按 SPACE 切换可见性",
  ariaToggleCellValue: "按 SPACE 切换单元格值",
  ariaUnchecked: "未选中",
  ariaVisible: "可见的",
  ariaSearchFilterValues: "搜索值",

  // ARIA Labels for Drop Zones

  ariaRowGroupDropZonePanelLabel: "行组",
  ariaValuesDropZonePanelLabel: "Values",
  ariaPivotDropZonePanelLabel: "列标签",
  ariaDropZoneColumnComponentDescription: "按 DELETE 删除",
  ariaDropZoneColumnValueItemDescription: "按 ENTER 更改聚合类型",
  ariaDropZoneColumnGroupItemDescription: "按 ENTER 排序",
  // used for aggregate drop zone, format: {aggregation}{ariaDropZoneColumnComponentAggFuncSeparator}{column name}
  ariaDropZoneColumnComponentAggFuncSeparator: " of ",
  ariaDropZoneColumnComponentSortAscending: "正序排序",
  ariaDropZoneColumnComponentSortDescending: "倒序排序",

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: "列菜单",
  ariaLabelCellEditor: "单元格编辑器",
  ariaLabelDialog: "对话框",
  ariaLabelSelectField: "选择字段",
  ariaLabelRichSelectField: "Rich Select Field",
  ariaLabelTooltip: "工具提示",
  ariaLabelContextMenu: "上下文菜单",
  ariaLabelSubMenu: "子菜单",
  ariaLabelAggregationFunction: "聚合函数",
  ariaLabelAdvancedFilterAutocomplete: "高级筛选自动完成",
  ariaLabelAdvancedFilterBuilderAddField: "高级筛选生成器添加字段",
  ariaLabelAdvancedFilterBuilderColumnSelectField: "高级筛选器生成器列选择字段",
  ariaLabelAdvancedFilterBuilderOptionSelectField:
    "Advanced Filter Builder Option Select Field",
  ariaLabelAdvancedFilterBuilderJoinSelectField:
    "Advanced Filter Builder Join Operator Select Field",

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ",",
  decimalSeparator: ".",

  // Data types
  true: "True",
  false: "False",
  invalidDate: "无效的日期",
  invalidNumber: "无效的数字",
  january: "一月",
  february: "二月",
  march: "三月",
  april: "四月",
  may: "五月",
  june: "六月",
  july: "七月",
  august: "八月",
  september: "九月",
  october: "十月",
  november: "十一月",
  december: "十二月"
};
