<template>
  <div style="display:inline-block">
    <!-- {{formId}}<br> -->
    <!-- {{fillIds}} -->
    <!-- <el-button size="mini" type="success" :disabled="!selectAll && fillIds.length==0" style="height:30px;margin-left:10px;" @click="showDialog">
      <span @click="showDialog">批量修改</span>
    </el-button> -->
    <!-- <span @click="showDialog">批量修改</span> -->
    <el-dialog title="表单详情" :visible.sync="showMultiEdit" top="5vh" custom-class="vFormViewerDialog" width="1000px" append-to-body destroy-on-close :before-close="close">
      <div class="container">
        <div class="edit-form-container">
          <div class="edit-form-left">
            <div class="select-tip">请选择要修改字段：</div>
            <el-select v-model="editFieldId" placeholder="请选择" :multiple="true" v-if="editableFields" clearable style="width:100%;line-height:28px;" @change="handleEditFieldChange">
              <el-option v-for="(widget,index) in editableFields" :key="index" :disabled="widget.disableSelect" :label="widget.options.label" :value="widget.id">
              </el-option>
            </el-select>
          </div>
          <div class="edit-form-right">
            <v-form-render ref="vformRender" :formJson="{}" :formData="formData" :optionData="formOptionData" :previewState="false">
              <template v-slot:myVxeTable="{fields,datas,isAdmin,viewItem,removeItem}">
                <fill-select-table-view :fields="fields" :datas="datas" :isAdmin="isAdmin" :viewItem="viewItem" :removeItem="removeItem" :onHandlePreviewImg="onHandlePreviewImg"></fill-select-table-view>
              </template>
            </v-form-render>
            <div v-if="!editFieldId" class="edit-form-empty">
              请先选择编辑的字段
            </div>
          </div>

        </div>
        <div style="text-align:center;margin-top:15px;" @click="devMode">
          <el-button v-if="dev==3" type="primary" size="small" @click.stop="getFormData">获取数据</el-button>
          <el-button type="default" size="small" @click.stop="close">关闭</el-button>
          <el-button type="primary" size="small" @click.stop="save">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { batchUpdate as fillBatchUpdate } from '@/api/system/form.js'

import FillSelectTableView from '@/views/system/form/components/fillSelector/tableView'
export default {
  components: {
    FillSelectTableView
  },
  props: {
    formId: {
      type: String
    },
    selectAll: {
      type: Boolean
    },
    formJson: {
      type: String | Object
    },
    formOptionData: {
      type: String | Object
    },
    fillIds: {
      type: Array
    },
    refresh: {
      type: Function
    },
    fillCacheKey: {
      type: String
    },
    excludeIds: {
      type: Array
    },
    showMultiEdit: {
      default: false,
      type: Boolean
    },
    viewConfig: {
      type: Object,
      default: ()=>{}
    }
  },
  data () {
    return {
      editFieldId: [],
      isShow: false,
      editFormJson: {},
      formData: {},
      formConfigTemplate: {
        "modelName": "formData",
        "refName": "vForm",
        "rulesName": "rules",
        "labelWidth": 80,
        "labelPosition": "top",
        "size": "",
        "labelAlign": "label-left-align",
        "cssCode": "",
        "customClass": "",
        "functions": "",
        "layoutType": "PC",
        "onFormCreated": "",
        "onFormMounted": "",
        "onFormDataChange": "",
        "onFormValidate": ""
      },
      dev:0
    }
  },
  computed: {
    editableFields: function () {
      if (this.formJson && this.formJson.widgetList) {
        let exclude = ["ocrcode",'rich-editor','html-text'];

        if(this.viewConfig && this.viewConfig.viewReadonlyFields ){
          let readonlyFieldNames = this.viewConfig.viewReadonlyFields.map(x=>x.name);
          
           return this.formJson.widgetList
              .filter(x => exclude.indexOf(x.type) == -1).map(x=>{
                x.disableSelect = readonlyFieldNames.includes(x.options.name) ;
                return x
              });
        }else{
          return this.formJson.widgetList.filter(x => exclude.indexOf(x.type) == -1);
        }
      }
      else {
        return null;
      }
    }
  },
  methods: {
    showDialog () {
      this.isShow = true;
    },
    save () {
      // 不触发formValidator校验，获取数据
      let fillJson = this.$refs.vformRender.getFormData(false)

      delete fillJson._navigator_;

      console.log("更改后的数据", fillJson, this.fillCacheKey)

      let fieldTypes={}, fieldLabel={};

      this.editFieldId.forEach(fieldId=>{
        let selectedField = this.editableFields.find(x => x.options.name === fieldId);
        fieldTypes[selectedField.options.name] = selectedField.type;
        fieldLabel[selectedField.options.name] = selectedField.options.label;
      })
      
      fillBatchUpdate(
        this.formId,
        (this.selectAll ? undefined : this.fillIds.map(x => x.fillId)),
        (this.selectAll ? 1 : 0),
        fillJson,
        (this.selectAll ? this.fillCacheKey : undefined),
        (this.selectAll ? this.excludeIds : undefined),
        '', '',
        fieldLabel,fieldTypes
      )
        .then((data) => {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          if (this.refresh) {
            this.refresh();
          }
          this.close()
        }).catch((err) => {
          this.msgError(err)
        })


    },
    close () {
      this.editFieldId = null;
      this.$emit('update:showMultiEdit', false)
    },
    devMode(){
      this.dev++;
      if(this.dev>3){
        this.dev=0;
      }
    },
    getFormData(){
      this.$refs.vformRender.getFormData().then((data)=>{
        delete data._navigator_
        console.log(data)
      })
    },
    onHandlePreviewImg(){

    },
    handleEditFieldChange(){
      let formJson = this.formJson;
      let newFormJson = {};
      newFormJson.formConfig = this.formConfigTemplate;
      newFormJson.widgetList = formJson.widgetList.filter(x => this.editFieldId.includes(x.id));
      newFormJson.filedList = formJson.fieldList.filter(x => this.editFieldId.includes(x.name));

      if(newFormJson.widgetList.length>0){
        newFormJson.widgetList.forEach(w=>{
          // w.options.labelHidden = true;
          w.options.hidden = false;
          w.options.disabled = false;
          w.options.readonly = false;

          if(this.viewConfig && this.viewConfig.viewReadonlyFields ){
            if(this.viewConfig.viewReadonlyFields.some(x=>x.name===  w.options.name)){
              w.options.disabled = true;
            }
          }
        })

      }
      this.$refs.vformRender.setFormJson(newFormJson)
    }

  }
}
</script>
<style lang="scss" scoped>
.edit-form-container {
  display: inline-flex;
  width: 100%;

  justify-content: space-between;
  align-content: flex-start;
  flex-direction: column;
}
.edit-form-left {
  display: inline-flex;
  width: 100%;
  padding-bottom:10px;
  margin-bottom:20px;
  border-bottom:solid 1px #dcdfe6;

  .select-tip {
    // height: 36px;
    // line-height: 36px;
    padding-top:5px;
    display: inline-block;
    width: 160px;
  }
}
.edit-form-right {
  display: inline-block;
  width: 100%;
  margin-left: 20px;

  .edit-form-empty {
    height: 36px;
    line-height: 36px;
    text-align: center;
    color: #dcdfe6;
    border: solid 1px #dcdfe6;
  }
}
.vFormViewerDialog {
  .el-dialog__header {
    padding: 0px 20px;
  }
}
</style>
