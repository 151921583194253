<template>
  <div class="aggrid">
    <el-tag v-if="value" type="info" size="small">{{ value.label }}</el-tag>
  </div>
</template>

<script>

export default {
  data () {
    return {
      value: '',
    }
  },
  beforeMount () {
    if(this.params.value){
      const dicts = this.params.colDef.dict
      const dict = dicts.find(item => item.value === this.params.value)
      if(dict){
        this.value = dict
      }
    }
  },
};
</script>

