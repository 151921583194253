var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aggrid" },
    [
      _c(
        "p",
        {
          staticStyle: {
            color: "#3476f0",
            cursor: "pointer",
            "font-size": "13px",
          },
          on: {
            click: function ($event) {
              _vm.showSubformDialog = true
            },
          },
        },
        [_vm._v("点击查看详情")]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.headerName,
            visible: _vm.showSubformDialog,
            width: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSubformDialog = $event
            },
          },
        },
        [
          _c("ag-grid-table", {
            attrs: {
              columnDefs: _vm.columnDefs,
              rowData: _vm.rowData,
              showSideBar: false,
              height: "600px",
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.showSubformDialog = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }