<template>
  <div>
    <ag-grid-vue ref="agGrid" :context="context" :class="'ag-theme-quartz'" :style="{ height: rewriteHeight }"
      :columnDefs="columnDefs" :rowData="rowData" :gridOptions="gridOptions" :sideBar="rewriteSideBar"
      :statusBar="rewriteStatusBar" :excelStyles="tableExcelStyles" :rowDragMultiRow="true" :enableCharts="true"
      :enableRangeSelection="true" :enableRangeHandle="true" :enableAdvancedFilter="enableAdvancedFilter"
      :rowGroupPanelShow="rowGroupPanelShow" :pagination="true" :paginationPageSize="paginationPageSize"
      :paginationPageSizeSelector="paginationPageSizeSelector" :loadingOverlayComponent="loadingOverlayComponent"
      :loadingOverlayComponentParams="loadingOverlayComponentParams" :tooltipShowDelay="tooltipShowDelay"
      :tooltipHideDelay="tooltipHideDelay" :autoSizeStrategy="autoSizeStrategy" :getContextMenuItems="getContextMenuItems"
      :defaultExcelExportParams="defaultExcelExportParams"
      @grid-ready="onGridReady" @selection-changed="onSelectionChanged" @filterChanged="onFilterChanged">
    </ag-grid-vue>
  </div>
</template>

<script>
import "ag-grid-enterprise";
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import { AgGridVue } from "ag-grid-vue";
import {
  EXCELSTYLES,
  GRID_OPTIONS,
  SIDEBAR_CONFIGURATION,
  STATUSBAR_CONFIGURATION
} from "@/components/AgGridTable/agGridConfig"; // 引入表格配置

import './styles.css';
// 引入加载数据自定义提示
import CustomLoadingOverlay from './components/overlay/CustomLoadingOverlay.vue';

import UserSelectCellRenderer from "./components/cellRenderer/UserSelectCellRenderer";
import RadioCellRenderer from "./components/cellRenderer/RadioCellRenderer";
import CheckboxCellRenderer from "./components/cellRenderer/CheckboxCellRenderer";
import SelectCellRenderer from "./components/cellRenderer/SelectCellRenderer";
import DateCellRenderer from "./components/cellRenderer/DateCellRenderer";
import NumberCellRenderer from "./components/cellRenderer/NumberCellRenderer";
import FileUploadCellRenderer from "./components/cellRenderer/FileUploadCellRenderer";
import PictureUploadCellRenderer from "./components/cellRenderer/PictureUploadCellRenderer";
import PictureSelectCellRenderer from "./components/cellRenderer/PictureSelectCellRenderer";
import CascaderCellRenderer from "./components/cellRenderer/CascaderCellRenderer";
import RateCellRenderer from "./components/cellRenderer/RateCellRenderer";
import RegionCellRenderer from "./components/cellRenderer/RegionCellRenderer";
import AddressCellRenderer from "./components/cellRenderer/AddressCellRenderer";
import NationalityCellRenderer from "./components/cellRenderer/NationalityCellRenderer";
import SignCellRenderer from "./components/cellRenderer/SignCellRenderer";
import FillUserCellRenderer from "./components/cellRenderer/FillUserCellRenderer";
import StatusCellRenderer from "./components/cellRenderer/StatusCellRenderer";
import OperationCellRenderer from "./components/cellRenderer/OperationCellRenderer";
import FillSelectCellRenderer from "./components/cellRenderer/FillSelectCellRenderer";
import RichEditorCellRenderer from "./components/cellRenderer/RichEditorCellRenderer";
import SubFormCellRenderer from "./components/cellRenderer/SubFormCellRenderer";
import PayStatusCellRenderer from "./components/cellRenderer/PayStatusCellRenderer";

import UserSelectTooltip from "./components/tooltip/UserSelectTooltip";
import CheckboxTooltip from "./components/tooltip/CheckboxTooltip";
export default {
  name: "AgGridTable",
  components: {
    AgGridVue,
    CustomLoadingOverlay,

    SelectCellRenderer,
    UserSelectCellRenderer,
    RadioCellRenderer,
    CheckboxCellRenderer,
    DateCellRenderer,
    NumberCellRenderer,
    FileUploadCellRenderer,
    PictureUploadCellRenderer,
    PictureSelectCellRenderer,
    CascaderCellRenderer,
    RateCellRenderer,
    RegionCellRenderer,
    AddressCellRenderer,
    NationalityCellRenderer,
    SignCellRenderer,
    FillUserCellRenderer,
    StatusCellRenderer,
    OperationCellRenderer,
    FillSelectCellRenderer,
    RichEditorCellRenderer,
    SubFormCellRenderer,
    PayStatusCellRenderer,

    UserSelectTooltip,
    CheckboxTooltip
  },
  props: {
    columnDefs: {
      type: Array,
      default: () => []
    },
    rowData: {
      type: Array,
      default: () => []
    },
    loadding: {
      type: Boolean,
      default: false
    },
    // 可以传入button按钮，显示在表格头部右侧
    toolbar: {
      type: Array,
      default: () => []
    },
    // context可以实现表格组件和列组件进行v-model通信
    context: {
      type: Object,
      required: false,
      default: () => ({})
    },
    // 表格高度
    height: {
      type: [Number, String],
      default: 200
    },
    // 表格loading效果
    loading: {
      type: Boolean,
      default: false
    },
    // 表格侧边栏显示隐藏
    showSideBar: {
      type: Boolean,
      default: true
    },
    // 表格状态栏显示隐藏
    showStatusBar: {
      type: Boolean,
      default: true
    },
    // 启用高级过滤器
    enableAdvancedFilter: {
      type: Boolean,
      default: false
    },
    // 表格导出合并样式
    excelStyles: {
      type: Array,
      default: () => []
    },
    // 显示行组面板设置
    rowGroupPanelShow: {
      type: String,
      default: "hide"
    },
    // 启用逻辑分页
    pagination: {
      type: Boolean,
      default: true
    },
    // 分页每页显示数量
    paginationPageSize: {
      type: [Number, String],
      default: 50
    },
    // 分页每页显示数量选择器
    paginationPageSizeSelector: {
      type: Array,
      default: () => [50, 100, 200]
    },
    searchText: {
      type: String,
      default: ''
    },
    enableCharts: {
      type: Boolean,
      default: true
    },
    autoSizeStrategy: {
      type: Object,
      required: false,
      default: () => ({
        type: 'fitGridWidth',
        defaultMinWidth: 100,
      })
    },
    defaultExcelExportParams: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data () {
    return {
      gridApi: null,
      loadingOverlayComponent: 'CustomLoadingOverlay',
      loadingOverlayComponentParams: {
        loadingMessage: '正在努力加载数据,请稍等...'
      },
      tooltipShowDelay: 0,
      tooltipHideDelay: 3000,
    };
  },
  computed: {
    gridOptions () {
      return GRID_OPTIONS;
    },
    rewriteHeight () {
      if (typeof this.height === "number") {
        return `calc(100vh - ${this.height}px)`;
      } else {
        return this.height;
      }
    },
    tableExcelStyles () {
      return EXCELSTYLES;
    },
    rewriteSideBar () {
      return this.showSideBar ? SIDEBAR_CONFIGURATION : false;
    },
    rewriteStatusBar () {
      return this.showStatusBar ? STATUSBAR_CONFIGURATION : false;
    },
  },
  watch: {
    searchText () {
      this.gridApi.setGridOption(
        'quickFilterText',
        this.searchText
      );
    }
  },
  beforeMount () {
    this.context = {
      agGridParent: this
    }
  },
  created () {

  },
  mounted(){

  },
  methods: {

    onGridReady (params) {
      // 获取gridApi
      this.gridApi = params.api;

    },
    // 右键菜单
    getContextMenuItems () {
      var result = [
        'copy', // 复制
        'copyWithHeaders', // 同时复制表头
        'separator', // 分割线
        // 'chartRange', // 范围图表
        // 'pivotChart', // 数据透视图
        'excelExport', // excel导出
      ];
      return result;
    },
    onSelectionChanged (event) {
      this.$emit('selectedRowsEvent', event.api.getSelectedRows())
    },
    onFilterChanged (event) {
      console.log(event.api.getFilterModel())
      this.$emit('filterChangedEvent', event.api.getFilterModel())
    },
    onBtShowLoading () {
      this.gridApi.showLoadingOverlay();
    },
    onBtHide () {
      this.gridApi.hideOverlay();
    },
    closeFilterModel (field) {
      this.gridApi.destroyFilter(field)
    }
  }
};
</script>
