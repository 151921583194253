<template>
  <div class="aggrid">
    <p>{{ value }}</p>
  </div>
</template>

<script>

export default {
  data () {
    return {
      value: '',
    }
  },
  beforeMount () {
    this.value = this.params.value;
  },
};
</script>

