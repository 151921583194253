var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aggrid" },
    [
      _c(
        "el-tooltip",
        {
          attrs: {
            "popper-class": "file-info",
            effect: "light",
            placement: "top",
            "open-delay": 300,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "file-info",
              staticStyle: { width: "80px" },
              attrs: { slot: "content" },
              slot: "content",
            },
            [
              _vm._v(" 编号：" + _vm._s(_vm.fillUserNumber) + " "),
              _c("br"),
              _c("span", [
                _vm._v("所属部门: "),
                !_vm.agencyDeptList ? _c("span", [_vm._v("--")]) : _vm._e(),
              ]),
              _c("br"),
              _vm._l(_vm.agencyDeptList, function (item, index) {
                return _c("span", { key: index }, [
                  _c("span", [
                    _vm._v(_vm._s(index + 1) + "：" + _vm._s(item.deptName)),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                ])
              }),
            ],
            2
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                cursor: "pointer",
              },
            },
            [
              _vm.fillUserAvatarUrl
                ? _c("el-avatar", {
                    attrs: {
                      size: 25,
                      src:
                        _vm.fillUserAvatarUrl +
                        "?x-oss-process=image/resize,m_fixed,h_30,w_30",
                      icon: "el-icon-user-solid",
                    },
                  })
                : _vm._e(),
              _c(
                "span",
                { staticStyle: { "margin-left": "3px", "font-size": "13px" } },
                [_vm._v(_vm._s(_vm.fillUserName))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }