var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          attrs: {
            type: "text",
            status: "primary",
            size: "mini",
            content: "查看",
          },
          on: { click: _vm.onViewFillClick },
        },
        [_vm._v("查看")]
      ),
      _c("el-divider", { attrs: { direction: "vertical" } }),
      _c(
        "el-button",
        {
          attrs: {
            type: "text",
            size: "mini",
            status: "primary",
            content: "修改",
          },
          on: { click: _vm.onEditFillClick },
        },
        [_vm._v("修改")]
      ),
      true ||
      this.currentRowData.status === 1 ||
      this.currentRowData.status == 2
        ? _c("el-divider", { attrs: { direction: "vertical" } })
        : _vm._e(),
      true ||
      this.currentRowData.status === 1 ||
      this.currentRowData.status == 2
        ? _c(
            "el-button",
            {
              attrs: {
                type: "text",
                size: "mini",
                status: "warning",
                content: "删除",
              },
              on: { click: _vm.onDeleteManageFillClick },
            },
            [_vm._v("删除")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }