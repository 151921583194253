<template>
  <div class="aggrid">
    <el-tag round size="small">{{ status.label }}</el-tag>
  </div>
</template>

<script>

export default {
  data () {
    return {
      status: {},
    }
  },
  beforeMount () {
    if(this.params.value){
      const dicts = this.params.colDef.dict
      this.status = dicts.find(item => item.value == this.params.value)
    }
  },
};
</script>

