<template>
  <div class="aggrid">
    <p style="color: #3476f0; cursor: pointer;font-size: 13px;" @click="showSubformDialog = true">点击查看详情</p>
    <!-- 子表单查看对话框 -->
    <el-dialog :title="headerName" :visible.sync="showSubformDialog" width="60%" append-to-body>
      <ag-grid-table :columnDefs="columnDefs" :rowData="rowData" :showSideBar="false" :height="'600px'" />
      <div slot="footer" class="dialog-footer" style="margin-top: -30px;">
        <el-button type="primary" @click="showSubformDialog = false">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getFinalColumnDefs } from "@/utils/agGrid.js";
export default {
  name: 'SubFormCellRenderer',
  components: {
    AgGridTable: () => import("@/components/AgGridTable")
  },
  data () {
    return {
      showSubformDialog: false,
      headerName: '',
      rowData: [],
      columnDefs: [],
    }
  },
  beforeMount () {
    const arr = this.params.colDef.widgetList
    const originalColumnDefs = []
    arr.forEach(item => {
      let colDef = {
        defaultAggFunc : "count",
        dict: item.filterOptions,
        enableRowGroup : true,
        enableValue : true,
        field : item.name,
        fieldType : item.type,
        filter : true,
        headerName : item.displayName,
        hide : false
      }
      originalColumnDefs.push(colDef)
    });
    this.columnDefs = getFinalColumnDefs(originalColumnDefs, false, false);
    this.headerName = '【' + this.params.colDef.headerName + '】内容详情' || ''
    if (this.params.value) {
      this.rowData = this.params.value
    }
  },
};
</script>
