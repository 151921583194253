var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ag-overlay-loading-center" }, [
    _c("div", {
      staticClass: "ag-loading-center",
      attrs: { "aria-label": "loading" },
    }),
    _c("div", [_vm._v(_vm._s(_vm.params.loadingMessage))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }