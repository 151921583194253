var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "aggrid" }, [
    _vm.title
      ? _c(
          "p",
          {
            staticStyle: { color: "#3476f0", cursor: "pointer" },
            on: { click: _vm.onFillSelectViewClick },
          },
          [
            _c("i", { staticClass: "el-icon-link" }),
            _vm._v(" "),
            _c("span", { staticStyle: { "font-size": "13px" } }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }