var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "aggrid" },
    [
      _vm.status.label
        ? _c(
            "el-tag",
            { attrs: { type: _vm.status.cssClass, round: "", size: "small" } },
            [_vm._v(_vm._s(_vm.status.label))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }