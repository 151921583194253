<template>
  <div class="aggrid">
    <p style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{ value }}</p>
  </div>
</template>

<script>

export default {
  data () {
    return {
      list: [],
      value: '',
    }
  },
  beforeMount () {
    const { colDef, data } = this.params;
    let filedName = colDef.field.replace("fill_json.",'') + '__label';
    if(data.fillJson){
      this.value = data.fillJson[filedName]
    }
  },
};
</script>
