export default {
  template: `
      <div class="aggrid-custom-tooltip">
          <p><span>编号：{{ fillUserNumber }}</span></p>
          <p>
            <span>所属部门: </span>
            <p v-if="agencyDeptList && agencyDeptList.length > 0">
              <span v-for="(item, index) in agencyDeptList">
                <p>{{ index + 1 }} : {{ item.deptName }}</p>
              </span></span>
            </p>
            <p v-else>--</p>
          </p>
      </div>
    `,
  data: function () {
    return {
      fillUserNumber: '--',
      agencyDeptList: [],
    };
  },
  beforeMount() {
    this.fillUserNumber = this.params.data.fillUserNumber;
    this.agencyDeptList = this.params.data.agencyDeptList;
  },
};
