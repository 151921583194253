var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "aggrid" }, [
    _c(
      "p",
      {
        staticStyle: {
          "text-overflow": "ellipsis",
          overflow: "hidden",
          "white-space": "nowrap",
        },
      },
      _vm._l(_vm.list, function (item) {
        return _c(
          "el-tag",
          {
            key: item.value,
            staticStyle: { "margin-right": "5px" },
            attrs: { size: "small", type: "info" },
          },
          [_vm._v(_vm._s(item.label))]
        )
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }