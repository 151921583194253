<template>
  <div class="aggrid">
    <p v-if="value">
      <el-tag size="small">{{ value }}</el-tag>
    </p>
  </div>
</template>

<script>

export default {
  data () {
    return {
      value: ''
    }
  },
  beforeMount () {
    this.value = this.params.value
  },
};
</script>



