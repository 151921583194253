var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block" } },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "确认删除",
            visible: _vm.showMultiDelete,
            "custom-class": "vFormViewerDialog",
            width: "400px",
            "append-to-body": "",
            "before-close": _vm.close,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showMultiDelete = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "container",
              staticStyle: { "margin-top": "-20px" },
            },
            [
              _c("div", { staticClass: "select-tip" }, [
                _vm._v("请输入表单名称 "),
                _c(
                  "span",
                  {
                    staticStyle: {
                      color: "#F18711",
                      "margin-left": "5px",
                      "margin-right": "5px",
                      cursor: "pointer",
                    },
                    on: { click: _vm.copy },
                  },
                  [_vm._v(_vm._s(_vm.formName))]
                ),
                _vm._v("以确认删除"),
              ]),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: { placeholder: "", clearable: "" },
                    model: {
                      value: _vm.confirmFormName,
                      callback: function ($$v) {
                        _vm.confirmFormName = $$v
                      },
                      expression: "confirmFormName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: { "text-align": "center", "margin-top": "15px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("关闭")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "warning", size: "small" },
                      on: { click: _vm.confirm },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }